import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import $ from 'jquery'
import inscriptionObject from '../../assets/data/inscriptions';
import DesktopTraitsBar from '../TraitsBarMenu/DesktopTraitsBar';
import Modal from './Modal';
import Dragons from './Dragons';
  
  export const downloadRef = React.createRef();
  export const memeRef = React.createRef();
  export const memeSizeRef = React.createRef();

function DragonsContainer(props){
    const [imageID, setImageID] = useState('37671')
    const [ordID, setOrdID] = useState('d18b749884a157eba04c013c25c3a538b5a23f7b04d9fd294f5e137dc5e2323ai0')
    const navigate = useNavigate()
      
      function setID({target}){
        setImageID((target.alt).replace('#', ''))
        setOrdID(inscriptionObject[(target.alt).replace('#', '')])
        console.log(imageID)
      }

      function templateChosen(templatePth){
        window.sessionStorage.setItem('template', templatePth)
        navigate('/memes')
      }


      function toggleDownloadDiv(){
        downloadRef.current.classList.toggle('d-none');
      }
      
      function toggleMemeDiv(){
        memeRef.current.classList.toggle('d-none');
        memeSizeRef.current.classList.toggle('memeDiv');
      }

      function downloadImg(uri, name) {
        var link = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        link = null;
      }
      

      useEffect(() => {
        const modal = document.getElementById('modal')
        modal.addEventListener('hide.bs.modal', () => {
            downloadRef.current.classList.add('d-none');
            memeRef.current.classList.add('d-none');
            memeSizeRef.current.classList.remove('memeDiv');
        }
    )})


    return (
        <div className="row mt-4">
            <DesktopTraitsBar menu={props.menu} onInput={props.searchHandler} toggleFilterOptions={props.toggleFilterOptions}/>
            <Modal toggleDownload={toggleDownloadDiv} toggleMeme={toggleMemeDiv} imageID={imageID} imageOID={ordID} chosenTemplate={templateChosen} downloadImg={downloadImg}/>
            <Dragons setID={setID} />
        </div>
    )
}


export default DragonsContainer;