import '../../css/home.css'
import '../../css/footer.css'

function Footer(){
    function today(){
        const today = new Date();
        return today.getFullYear()
    }
    return (
        <div className="row border-top mt-5">
            <div className="container">
                <div className="d-flex justify-content-center p-4">
                    <div className="d-flex col-8 ms-5 me-1 pe-4">
                        <span>Copyright {today()} Engraved Dragons</span>
                    </div>
                    
                    <div className="d-flex col-3 justify-content-end footerBtns align-items-center">
                    <a href='https://linktr.ee/engraveddragons' target="_blank" className='socialBtn hov'><i className="fa-solid fa-link me-3 linktree"/></a><a href='https://discord.gg/gdcszaPb9B' target="_blank" className='socialBtn hov'><i className="fab fa-discord me-3 discord"/></a><a href='https://twitter.com/engraveddragons' target="_blank" className='socialBtn hov'><i className="fab fa-twitter me-3 twitter"/></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;