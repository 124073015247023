    let searchParamsArr = []
    let skinsArr = []
    let bgArr = []
    let clothesArr = []
    let headArr = []
    let glassesArr = []
    let smokingArr = []
    let earringArr = []

    export const toggleFilterOptions = (e, option) => {
        if(e.target.getAttribute('data-item') === 'Skins'){
            let filterSkins = document.querySelectorAll("[data-item='Skins']")
            for(let i = 0; i < filterSkins.length; i++){
                filterSkins[i].style.color = 'rgb(33, 37, 41)'
            }
            const index = skinsArr.indexOf(option + ' Skin')
            if(index !== -1){
                skinsArr = skinsArr.filter(o => o !== option + ' Skin')
                e.target.style.color = 'rgb(33, 37, 41)'
            } else {
                e.target.style.color = '#e73600'
                skinsArr = [option + ' Skin']
            }
        } else if(e.target.getAttribute('data-item') === 'Background'){
            let filterBackground = document.querySelectorAll("[data-item='Background']")
            for(let i = 0; i < filterBackground.length; i++){
                filterBackground[i].style.color = 'rgb(33, 37, 41)'
            }
            const index = bgArr.indexOf(option + ' Background')
            if(index !== -1){
                bgArr = bgArr.filter(o => o !== option + ' Background')
                e.target.style.color = 'rgb(33, 37, 41)'
            } else {
                e.target.style.color = '#e73600'
                bgArr = [option + ' Background']
            }
        } else if(e.target.getAttribute('data-item') === 'Clothes'){
            let filterClothes = document.querySelectorAll("[data-item='Clothes']")
            for(let i = 0; i < filterClothes.length; i++){
                filterClothes[i].style.color = 'rgb(33, 37, 41)'
            }
            const index = clothesArr.indexOf(option)
            if(index !== -1){
                clothesArr = clothesArr.filter(o => o !== option)
                e.target.style.color = 'rgb(33, 37, 41)'
            } else {
                e.target.style.color = '#e73600'
                clothesArr = [option]
            }
        } else if(e.target.getAttribute('data-item') === 'Head'){
            let filterHead = document.querySelectorAll("[data-item='Head']")
            for(let i = 0; i < filterHead.length; i++){
                filterHead[i].style.color = 'rgb(33, 37, 41)'
            }
            const index = headArr.indexOf(option)
            if(index !== -1){
                headArr = headArr.filter(o => o !== option)
                e.target.style.color = 'rgb(33, 37, 41)'
            } else {
                e.target.style.color = '#e73600'
                headArr = [option]
            }
        } else if(e.target.getAttribute('data-item') === 'Glasses'){
            let filterGlasses = document.querySelectorAll("[data-item='Glasses']")
            for(let i = 0; i < filterGlasses.length; i++){
                filterGlasses[i].style.color = 'rgb(33, 37, 41)'
            }
            const index = glassesArr.indexOf(option)
            if(index !== -1){
                glassesArr = glassesArr.filter(o => o !== option)
                e.target.style.color = 'rgb(33, 37, 41)'
            } else {
                e.target.style.color = '#e73600'
                glassesArr = [option]
            }
        } else if(e.target.getAttribute('data-item') === 'Smoking'){
            let filterSmoking = document.querySelectorAll("[data-item='Smoking']")
            for(let i = 0; i < filterSmoking.length; i++){
                filterSmoking[i].style.color = 'rgb(33, 37, 41)'
            }
            const index = smokingArr.indexOf(option)
            if(index !== -1){
                smokingArr = smokingArr.filter(o => o !== option)
                e.target.style.color = 'rgb(33, 37, 41)'
            } else {
                e.target.style.color = '#e73600'
                smokingArr = [option]
            }
        } else if(e.target.getAttribute('data-item') === 'Earring'){
            let filterEarring = document.querySelectorAll("[data-item='Earring']")
            for(let i = 0; i < filterEarring.length; i++){
                filterEarring[i].style.color = 'rgb(33, 37, 41)'
            }
            const index = earringArr.indexOf(option)
            if(index !== -1){
                earringArr = earringArr.filter(o => o !== option)
                e.target.style.color = 'rgb(33, 37, 41)'
            } else {
                e.target.style.color = '#e73600'
                earringArr = [option]
            }
        }

        searchParamsArr = skinsArr.concat(bgArr, clothesArr, headArr, glassesArr, smokingArr, earringArr)

        filterItems()
    }

    const filterItems = () => {
        const searchContent = document.querySelectorAll("#content div");
        searchContent.forEach(el => {
            const content = el.textContent.trim();
            const hasWord = searchParamsArr.every/*every*/((word) => content.includes(word));
            el.classList.toggle("d-none", !hasWord);
        });
    }

    export const handleSearchInput = ({target}) => {
        if(target.value == []){
            filterItems();
        } else {
            filterSearchInput({target})
        }
    }

    const filterSearchInput = ({target}) => {
        const searchInput = document.querySelector("#searchInput");
        const searchInputMobile = document.querySelector("#searchInputMobile");
        if(target.id === 'searchInputMobile'){
            searchInput.value = '';
        } else if(target.id === 'searchInput'){
            searchInputMobile.value = '';
        }

        const regExp = /^[0-9]+$/
        if(regExp.test(target.value)){
            searchInput.classList.remove('is-invalid')
            searchInputMobile.classList.remove('is-invalid')
        } else {
            searchInput.classList.add('is-invalid')
            searchInputMobile.classList.remove('is-invalid')
        }
        

        const searchContent = document.querySelectorAll("#content div");
        const filterInscription = (text) => {
            const words = [text.trim()];
            searchContent.forEach(el => {
                const content = el.textContent.trim().toLowerCase();
                const hasWord = words.every/*every*/((word) => content.includes(word));
                el.classList.toggle("d-none", !hasWord);
            });
        }
        filterInscription(target.value);
    }

    export const resetFilter = () => {
        const searchInput = document.querySelector("#searchInput");
        const searchInputMobile = document.querySelector("#searchInputMobile");

        searchInput.value = '';
        searchInputMobile.value = '';

        let filterSkins = document.querySelectorAll("[data-item='Skins']")
        let filterBackground = document.querySelectorAll("[data-item='Background']")
        let filterClothes = document.querySelectorAll("[data-item='Clothes']")
        let filterHead = document.querySelectorAll("[data-item='Head']")
        let filterGlasses = document.querySelectorAll("[data-item='Glasses']")
        let filterSmoking = document.querySelectorAll("[data-item='Smoking']")
        let filterEarring = document.querySelectorAll("[data-item='Earring']")

        searchParamsArr = []
        skinsArr = []
        bgArr = []
        clothesArr = []
        headArr = []
        glassesArr = []
        smokingArr = []
        earringArr = []

        for(let i = 0; i < filterSkins.length; i++){
            filterSkins[i].style.color = 'rgb(33, 37, 41)'
        }
        for(let i = 0; i < filterBackground.length; i++){
            filterBackground[i].style.color = 'rgb(33, 37, 41)'
        }
        for(let i = 0; i < filterClothes.length; i++){
            filterClothes[i].style.color = 'rgb(33, 37, 41)'
        }
        for(let i = 0; i < filterHead.length; i++){
            filterHead[i].style.color = 'rgb(33, 37, 41)'
        }
        for(let i = 0; i < filterGlasses.length; i++){
            filterGlasses[i].style.color = 'rgb(33, 37, 41)'
        }
        for(let i = 0; i < filterSmoking.length; i++){
            filterSmoking[i].style.color = 'rgb(33, 37, 41)'
        }
        for(let i = 0; i < filterEarring.length; i++){
            filterEarring[i].style.color = 'rgb(33, 37, 41)'
        }

        filterItems()
    }