import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import FilerobotImageEditor, { TABS, TOOLS } from 'react-filerobot-image-editor';
import '../../css/imageeditor.css'

function ImageEditor() {
  const [isImgEditorShown, setIsImgEditorShown] = useState(false);
  let chosenTemplate = window.sessionStorage.getItem('template') ? window.sessionStorage.getItem('template') : "template/rectangle.png";

  const openImgEditor = () => {
    setIsImgEditorShown(true);
  };

  const closeImgEditor = () => {
    setIsImgEditorShown(false);
  };

  const setTemplate = template => {
    window.sessionStorage.setItem('template', template);
    chosenTemplate = window.sessionStorage.getItem('template');
    setIsImgEditorShown(false);
    newImageEditor()
  }

  const clickBtn = useEffect(() => {
    const btn = document.getElementById('openBtn')
    btn.click()
  })

  function downloadImg(uri, name) {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link = null;
  }

  /*  useEffect(() => {
    return () => {
        window.sessionStorage.setItem('template' ,'template/rectangle.png')
    }
})*/
  

  const newImageEditor = () => {

        return(
            isImgEditorShown && (
        <FilerobotImageEditor
          source={require(`../../assets/images/${chosenTemplate}`)}
          onSave={(editedImageObject, designState) => {
            downloadImg(editedImageObject.imageBase64, editedImageObject.fullName)
            }
          }
          annotationsCommon={{
            fill: '#3d3d3d',
          }}
          theme={{typography: {fontFamily:'sans-serif'}, palette: {
          'bg-primary': '#7777771a',
}}}

          Text={{ text: 'DRAGOOOOOOOON' , fontFamily: 'Press Start 2P', fonts: [
            { label: 'Press Start 2P', value: 'Press Start 2P' },
            'Arial',
            'Tahoma',
            'Sans-serif',
            { label: 'Comic Sans', value: 'Comic-sans' },
          ],}}
          Rotate={{ angle: 90, componentType: 'slider' }}
          Crop={{
            presetsItems: [
              {
                titleKey: 'classicTv',
                descriptionKey: '4:3',
                ratio: 4 / 3,
                // icon: CropClassicTv, // optional, CropClassicTv is a React Function component. Possible (React Function component, string or HTML Element)
              },
              {
                titleKey: 'cinemascope',
                descriptionKey: '21:9',
                ratio: 21 / 9,
                // icon: CropCinemaScope, // optional, CropCinemaScope is a React Function component.  Possible (React Function component, string or HTML Element)
              },
            ],
            presetsFolders: [
              {
                titleKey: 'socialMedia', // will be translated into Social Media as backend contains this translation key
                // icon: Social, // optional, Social is a React Function component. Possible (React Function component, string or HTML Element)
                groups: [
                  {
                    titleKey: 'facebook',
                    items: [
                      {
                        titleKey: 'profile',
                        width: 180,
                        height: 180,
                        descriptionKey: 'fbProfileSize',
                      },
                      {
                        titleKey: 'coverPhoto',
                        width: 820,
                        height: 312,
                        descriptionKey: 'fbCoverPhotoSize',
                      },
                    ],
                  },
                ],
              },
            ],
          }}
          tabsIds={[TABS.ADJUST, TABS.ANNOTATE, TABS.WATERMARK]} // or {['Adjust', 'Annotate', 'Watermark']}
          defaultTabId={TABS.ANNOTATE} // or 'Annotate'
          defaultToolId={TOOLS.TEXT} // or 'Text'
        />
            )
        )
  }

  return (
    
    <div>
      <div className="container">
            <div className="editor mt-5 shadow rounded overflow-hidden">
                <button onClick={openImgEditor} className="d-none" id="openBtn">Open</button>
                {newImageEditor()}
                
                <div className="editor_container"></div>
            </div>
            <div className='d-flex flex-wrap justify-content-around mt-5'>
                <div className='d-flex flex-wrap justify-content-center p-5 col-8 col-lg-3 text-center templates'>
                    <p>Start with an empty rectangle</p>
                    <img src={require('../../assets/images/template/rectex.png')} width="200px" height="100px"/>
                    <div className='col-12 justify-content-center align-self-end'>
                        <button className='btn btn-outline-secondary' onClick={() => {setTemplate("template/rectangle.png")}}>Start</button>
                    </div>
                </div>
                <div className='d-flex flex-wrap justify-content-center p-5 col-8 col-lg-3 text-center templates mb-5 mb-sm-0'>
                    <p>Start with an empty square</p>
                    <img src={require('../../assets/images/template/squareex.png')} width="100px" height="100px"/>
                    <div className='col-12 align-self-end'>
                      <div className='d-inline-flex align-items-center px-3 smallBtns'><div className='d-flex flex-wrap justify-content-center'><button className="btn btn-outline-secondary mx-2 mt-2 p-1 downloadButtons" onClick={() => setTemplate(`template/square565.png`)} data-bs-dismiss="modal">565px</button><button className="btn btn-outline-secondary mx-2 mt-2 p-1 downloadButtons" onClick={() => setTemplate(`template/square1000.png`)} data-bs-dismiss="modal">1000px</button><button className="btn btn-outline-secondary mx-2 mt-2 p-1 downloadButtons" onClick={() => setTemplate(`template/square1500.png`)} data-bs-dismiss="modal">1500px</button><button className="btn btn-outline-secondary mx-2 mt-2 p-1 downloadButtons" onClick={() => setTemplate(`template/square2000.png`)} data-bs-dismiss="modal">2000px</button><button className="btn btn-outline-secondary p-1 mx-2 mt-2 downloadButtons" onClick={() => setTemplate(`template/square2500.png`)} data-bs-dismiss="modal">2500px</button></div></div>
                    </div>
                </div>
                <div className='d-flex flex-wrap justify-content-center p-5 col-8 col-lg-3 text-center templates'>
                    <p>Get a speech or thought bubble</p>
                    <img src={require('../../assets/images/template/dragooon.png')} width="200px" height="100px"/>
                    <div className='col-12 justify-content-center align-self-end'>
                        <a href="https://pixelspeechbubble.com/" target={"_blank"}><button className='btn btn-outline-secondary'>Go</button></a>
                    </div>
                </div>
                <div className='d-flex flex-wrap justify-content-center p-5 col-8 col-lg-3 text-center templates'>
                    <p>To get a sign or more templates, go to the dragon showcase and choose your dragon. Click on meme templates or download's.</p>
                    <div className='col-12 align-self-end'>
                        <Link to="/dragons"><button className='btn btn-outline-secondary'>Go</button></Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default ImageEditor;