import image from '../assets/images/eggs/teaser.png'

import {Helmet} from "react-helmet";
import NavBar from '../components/NavBar/NavBar'
import Footer from '../components/Footer/Footer';

export default function EggShowcase(){
    return(
        <div className='overflowHidden'>
            <Helmet>
                <title>Engraved Dragons - Egg showcase</title>
                <meta property="og:title" content="Engraved Dragons - The Engraved Showcase" />
                <meta name="twitter:title" content="Engraved Dragons - The Engraved Showcase"></meta>
                <meta name="twitter:description" content="First ever Engraved Dragons on the Bitcoin blockchain. Check out The Engraved in our showcase! Filter their traits and search on Ordinal inscription number."></meta>
                <meta property="og:description" content="First ever Engraved Dragons on the Bitcoin blockchain. Check out The Engraved in our showcase! Filter their traits and search on Ordinal inscription number." />
                <meta name="description" content="First ever Engraved Dragons on the Bitcoin blockchain. Check out The Engraved in our showcase! Filter their traits and search on Ordinal inscription number."/>
                <meta property="og:type" content="website" />
            </Helmet>
            <div className='page-container'>
                <NavBar active={'Eggs'}/>
                <div className='content-wrapper'>
                    <div className="d-flex flex-wrap justify-content-center">
                        <div className='col-12 justify-content-center m-5'>
                            <center><img src={image} className="mt-5" style={{width: '20%'}}/></center>
                        </div>
                        <br/>
                        <center><p className='justify-content-center' style={{color: "black", fontSize: 'large'}}>Coming soon...</p></center>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}