import {Helmet} from 'react-helmet';
import NavBar from '../components/NavBar/NavBar';
import Banner from '../components/Banner/Banner';
import HomeContent from '../components/Home/HomeContent';
import Footer from '../components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function HomeContainer(){
    const navigate = useNavigate();

    /*function go(){
        navigate('/ancients')
    }
    
    useEffect(() => {
        go()
    })*/

    return (
        <div className='overflowHidden'>
            <Helmet>
                <title>Engraved Dragons - Ordinals on the Bitcoin blockchain</title>
                <meta property="og:title" content="Engraved Dragons - Ordinals on the Bitcoin blockchain" />
                <meta name="twitter:title" content="Engraved Dragons - Ordinals on the Bitcoin blockchain"></meta>
                <meta name="twitter:description" content="Engraved Dragons, with their immense power and ability to work together in harmony, remind us that true strength is not merely found in individual prowess, but also in the ability to cooperate and support one another towards a common goal."></meta>
                <meta property="og:description" content="Engraved Dragons, with their immense power and ability to work together in harmony, remind us that true strength is not merely found in individual prowess, but also in the ability to cooperate and support one another towards a common goal." />
                <meta name="description" content="Engraved Dragons, with their immense power and ability to work together in harmony, remind us that true strength is not merely found in individual prowess, but also in the ability to cooperate and support one another towards a common goal."/>
                <meta property="og:type" content="website" />
                <meta property='og:image' content='/logo512.png'/>
                <meta property="og:image:type" content="image/png"/>
            </Helmet>
            <NavBar active={'Home'}/>
            {/*<Banner/>
            <HomeContent/>*/}
            <Footer/>
        </div>
    )
}

export default HomeContainer;