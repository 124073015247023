const inscriptionObject = {
    "37671": "d18b749884a157eba04c013c25c3a538b5a23f7b04d9fd294f5e137dc5e2323ai0",
    "37718": "34a95ef00542f71f0d28c3fcf96971a94586b0587bcafcf51753251f227f6554i0",
    "38065": "f82af0826479ddd0e36d7f349fd9a6d52bf44e4ccdf83341e7248c8493eb501ai0",
    "38103": "39c4077cb074b1086f4edcd54c4103e676bb457a7e85258ba2d2824c9cadd249i0",
    "38127": "f8f5b66ca12f0866ab3f553d656d6758729c797f585f838160bd4eea46797461i0",
    "38166": "a5790f372374c27de01a92f014855499f336f77a5dc76ef47888374e183d7a13i0",
    "38197": "70ba129b36d2ae3288d68ad5e420caedbfcc22981d0f265a6eecfbbc4b076c63i0",
    "38225": "943c6c434bec64322e0a505dbdb8f59effc3d4fe00cd1dd61d6683cfb24e2726i0",
    "38226": "0e11334e35081ef6ee606ecf3b9f54cdf97701198e693c2d34599dce9db76195i0",
    "38230": "641303a06570ca25165770ed3e93744f5246965121ae04e3a874d3fb80cc4237i0",
    "38231": "3bf3a393ca330a6a6ca9efe9cc60363acd52781310ef1725ba132dfeae29973fi0",
    "38340": "ca3e0177e1b416294c2292fd87d226e8fdccbc9a14dc592a6878fae986395bddi0",
    "38341": "0e0c5cca0508993c875a43528f297e9bb5dec4ee9ebe437e7a5e84b3deed5ee1i0",
    "38407": "c6ae6912f77b4ae63974bdba0455e3d58d82b295a78f616abe04e2cbaee27c3fi0",
    "38408": "46bf6dffdb48570a4073e13beb46711efa38f8e4a8505f004273816b6d9a8fd0i0",
    "38454": "6fb70a6e44d3b0becde571b7f9c12379f42756488f0fe6a9b8ec47e53b83386ci0",
    "38455": "447e51fcb41ec5a708b2e92296c19cfc7a7150660587f65dce8ed5f529f45f75i0",
    "38527": "0985b7b2e457fd52dbcbfc90bbfe5be89b5d43f1da3d20b8f73beb31b0991c2ei0",
    "38529": "bc7142e235ac101f199164a35a6bdaeacf7eba56f85a14cfbafcb87189824d60i0",
    "38531": "781d87211d7d863afeef9180235c740804b0e2dbc4980e63adb2512c230e45d7i0",
    "38532": "712f84e70343c994746d1150b94a3b5553067ce866a6c2c18ee96ebfc5a7cdddi0",
    "38608": "ae06677d0716c1d645d08fc98c2ef6637db34069ac72ed9a5f877c23d7566209i0",
    "38609": "193eb6f08de5de9630ed52036cf780fd690703aec56bd1abc96128992f04b01di0",
    "38671": "a3836ffadb85f9f7b9b7639f659920e323e8225dfab14dd0b7874801a8eb4a30i0",
    "38672": "8cd74c74a77a9764e33892f49582cdbde9260ae589da8190441381515a6a1469i0",
    "38802": "6e1dfaf5a68a075cea429ee5cf5acf11c5ddc46c20c7ba1100aa75cf7228602fi0",
    "38803": "95fa8176754cc41e69a08a86591581d2fbe2f99f4d89f79cd958e9410f194a3ci0",
    "38804": "c0c235268cdbc5eecb7d36f7b9a007efa83f865c1becf60d9bf93790172dbe46i0",
    "38806": "4365d81ba66ebf824416c3bfd9af68b4606bd9fc6d18d8612c0d494021984b63i0",
    "38920": "369332d95fd37985e681e405fe2dda7612aa354723d7610fabaaa15c86100716i0",
    "38922": "ec12d4ae867eaa63033932d7f86f30794bd5a42431ec78d09d63e085c805247fi0",
    "38923": "2a0952d688699aa1a12992cde58f897a8b6468c9729264f964c58c3f5742718di0",
    "38924": "3a7e0a7fda3b53eedc1074c6d42cb986bf9329137a114f7ecd0807c113ae86a1i0",
    "39109": "f4f15d5f95f1fb8c1611e9c02e3ec88d8d2957e2409d6ae00624a7ddf47dd64ci0",
    "39110": "91e9f53fb33ac0eaae4a2133ef87b883c3a63ae14231fa932f54dcd5f54e4588i0",
    "39111": "5ba885189ada165ff19c061ae1b67e29c1409b8e49fea13319696369bbfabea2i0",
    "39112": "a8ace35bbbde8bf1bfe8b376d655f41b95479b79ca7d5e42e87fc9db80db7de6i0",
    "39220": "45b15943e5fecec36bcf95cd4418564164a399649069d436f8632ef87794570fi0",
    "39221": "8b839341c373d9a7aa9a0669e3f1daabb51926d562d48cde2ae1fd0381ae7a16i0",
    "39223": "2932a5473dabf1903ad6800573f04a8c498d03e0f1cc456c0442bbc62faa92b5i0",
    "39224": "d1fbe71b04612a53410aa3ff9d91055c24b6f50a12563018e4f9a2c21da162c4i0",
    "39225": "6b7dcbd78cc7c178fe325c8bbba05bd08dbfcdc9901fa0a4ee411c3cfca4f7fbi0",
    "39325": "98214b3b037107eac843709cb9e5064e0334a22d93a0269aa7644ef9cc619816i0",
    "39326": "5bd73bccc4018e20cae682e06b9790df010d9b500ffdb37014b454525a58871di0",
    "39327": "124ed002738b17e438abda45f30b363c988baa5fb7582e85687e391111831a1fi0",
    "39329": "60876f8ff85c6b4517d693c04df21f4e3c2a5807cd47477fa86310e5c3ed05d3i0",
    "39331": "efffa5153fdc89097a2f04bb19c83224b311d69870300006cea32f7537481bffi0",
    "39425": "7931a8ae575cea85c7eeff1d7319fb642f0e3ed01726f32ba58e4e5dd6b1bc04i0",
    "39427": "e1b11d16067827f33e253ab480de78b5df4396a173faad0f66e692b0ca73120ci0",
    "39428": "f1eb2f99af93be64c321da7135d478956778acb895846b53556f557e77b4b00di0",
    "39430": "c76f413cde48bcd79e9c75f9474b7141789621f7a743183a26f384bd18aaaf23i0",
    "39431": "db7d1802437607936b9416cf4453aaa8f1d80793e79d918320d2690e592ef126i0",
    "39436": "2146e35dbf6c04e079ff8f1824a78aa240045e83e7b5d5a9920aeed8863eea8fi0",
    "39438": "3b2c35b816eb5369db74b4677cd6303989d6904b9f0a6e3e6dca321d6a3bb8bdi0",
    "39439": "31f42510e7b90497354a2f258b49d3e4c498706e685dddb7e26789dec3b676bei0",
    "39440": "e324e06c2c747c71c1e5755913c2948f97f812b7f32e485082373302530584c9i0",
    "39442": "a0047a9b3f9550391b7fbe157003df177fb8872dad60117fc04021df46538acai0",
    "39506": "88ef5c5cc9b1ca8777897c352dab265a29afba7c9c5230ab33a6eb106ece740fi0",
    "39507": "ec21ea54a8627fb2dcae8de559a3e3370bb950c9caf2a338e964b8cf72f4452di0",
    "39508": "d046f7d6b6aac7819e27fe0f5cdcf7fe38990ca51c6036e6d6f1fe5a472b332fi0",
    "39511": "99e151102d507db328583ee3936096189e5f9ecf07af4b7e1b7a185f93fd1e5fi0",
    "39514": "affb7be4f11c3bee96821a8e10fc572acd8c22bfd234de991dc567e5019b8f96i0",
    "39515": "bd19e6e27222f361cec1d1160e185aeb371e6e5ddb8068c44887fe518132da98i0",
    "39516": "360d9aa2e96159fe06bdfa4afdcd790b6a74b66408ba3adc6a2b6c83bbbab2adi0",
    "39517": "84053afc87df5b43afaa237d40721aaa4e7eaaea5663cd574355ea51516c7cb6i0",
    "39518": "ad58242975e8499948e54ef366927499a39cb22418c20fe2c5ab106c516191dbi0",
    "39576": "6b8d0285002350bcc4ba05c4e45ecbd42d371aa4106eea9ccd36e54f4531f40bi0",
    "39577": "0f804f2cdc8ac63b63f2e7c93f70bfcd9b2088c24e03928686879985af8d5f36i0",
    "39578": "1d0a2592b9d969bf7288c795f3c6bc5640567205aade9ef6ad56df67982e123fi0",
    "39579": "46ee8ecd343d28e8921c3382d9e2f7aa65d44cae8fd35bab9d9f789f258e974di0",
    "39580": "150eb2390d0d086ba10c2e05bfefe5f6f2a2ceb0ba05056e7fc72b051f9f4265i0",
    "39581": "084140ba3db2afcff6f808932f260936400fce095d1f0b4d1eb88c4e16bb68b2i0",
    "39582": "c4fdd9311e31a96dd8decdfc1bbe45e80378c0ff566e7f04e74a29ba5d0b15c9i0",
    "39583": "80bd3c23621e0c1de6bce00a3a339e33cf5dac72e1e63f14d503383a70e933d2i0",
    "39584": "7c7c2cbbfe9a3ec6fbc11312bfccd56581de52ce2e512d43192849235e5c6fdei0",
    "39649": "b6a860ba7ff9df38bef93468db17dc93c2c4a712d50927a677d1f1e6829b9127i0",
    "39652": "e10cb9c3d861c3b8204abd80179702112e1213ab14f10c6582943aa1e962a975i0",
    "39653": "018fef1fd305475f47f0fc9e5ab751825442a82a135d39161ab6ac76a20e2885i0",
    "39654": "e7aa32224dafd677a619166be0f8db3f68dfc7721dd2a78e5ce7d90600fe4f8fi0",
    "39656": "2397fd5903037047290fc9d7995bf11216b90e6036a1f87c011a02ab2831a2bdi0",
    "39658": "f3ab1225139f8c01798dc6319c18d970ba3317b9a505f4099456a9e722e32ee2i0",
    "39659": "b411b60b929dd7776282f4d9fa72fbaea3c92cc061a3e2aa0637adcbd2b8ebfbi0",
    "39761": "f94a951edfdbd67e45ae43b581f5d6bc34587ee4d8e70d0e1a50a2d43b497033i0",
    "39762": "92d872edd33a67bc92a970b0547eaf1463b16d2a193d3f05823bad52cf677546i0",
    "39765": "651be16962f737df3f1406d00862b4932896e2a7ff45fcf89dd42eb0671506b4i0",
    "39767": "1fe4d8b45e5b5fa2a388166e762a9d4e90736b71f9003d933abe7c4a9f3789ebi0",
    "39836": "5b130baad6ab194a464e041e57e76e4b8b9571989e5cc83379e03a43721a5401i0",
    "39837": "01e1df1ccce9b7b2da73de40471115c6c2e899788277725df8d448370c189b05i0",
    "39838": "6436be679eb7a1bf4affd80acdca025190bb738745294177ad9ecc193c43551di0",
    "39839": "72ec4010c19c802ac1256a757ed4cc1211c40dd751971a2d885a6724acf63049i0",
    "39843": "8aee727bbd8cb1b7ef889dfeadee433baa32c3eb3d282637eef76a17fd1ff9fci0",
    "39925": "51aa1adf2f8eb033a0051c8c5696b17455eef6554f3c1f82ccc39af173479624i0",
    "39926": "ebf888c892a40074b84c69662ffd2388728f1c952b45d74432bc84242e832234i0",
    "39927": "b94606d6501a409d17235b6bb8880076efc7901e752c86f793bccdc39b9def7bi0",
    "39928": "b76f5e05f1375b624bac2ec5ad1c1f42fc1a6bf434284a4224428bacd86f7295i0",
    "39929": "d7ee784760951115c4f6b3dfc7620e58b25f9b5d73e80a2657939bf99c240ab5i0",
    "39930": "9a4d92d87ed9ae827b480a7853f801ba3046e7877b4b7c74fa55f144262991c5i0",
    "39931": "535b8f3b79b2d5e09b29749901a3d116a3e482364dcfdda71da00fe190ba1ff4i0",
    "40023": "d32f7a8835d27b01719defcc6a0a1669a0f5424c313b6350f4ab5c007ff948edi0",
    "40024": "4f872c9cfb7bc19bf7a14e310c07254c1180aac5c359fc453aab6533bc9f14f7i0"
}

export default inscriptionObject;