import logo from './404page.png';
import './App.css';
import NavBar from './components/NavBar/NavBar'
import Footer from './components/Footer/Footer'

function NotFound() {
  return (
    <div className="App">
      <NavBar/>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="404" />
      </header>
      <Footer/>
    </div>
  );
}

export default NotFound;
