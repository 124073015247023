import '../../css/listed.css'

function Listed(){
    return (
        <div className="container-fluid mt-5 d-flex flex-wrap justify-content-around">
            <h1 className='col-12 text-center mb-5 mt-4'>Find our collection</h1>
            <div className='col-10 col-sm-7 col-md-5 col-lg-4 col-xl-3 mt-4 mx-md-3 mx-lg-5 mx-xl-3 mb-5'>
                <a className='mt-4 listedLink' href='https://ordx.io/collections/engraved-dragons' target="_blank">
                    <div className="card listedCard shadow d-flex align-items-center p-3">
                        <h2 className='mt-4 '>ORDX</h2>
                        <img className='position-absolute listedImg mt-auto' src={require('../../assets/images/logos/ordx.png')}/>
                        <p className='text-center mt-auto'>Check us out on ORDX</p>
                    </div>
                </a>
            </div>
            <div className='col-10 col-sm-7 col-md-5 col-lg-4 col-xl-3 mt-4 mx-md-3 mx-lg-5 mx-xl-3 mb-5'>
                <a className='mt-4 listedLink' href='https://ordswap.io/collections/engraved-dragons' target="_blank">
                    <div className="card listedCard shadow d-flex align-items-center p-3">
                        <h2 className='mt-4'>Ordswap</h2>
                        <img className='position-absolute listedImg mt-auto' src={require('../../assets/images/logos/ordswap.png')}/>
                        <p className='text-center mt-auto'>See our collection on Ordswap</p>
                    </div>
                </a>
            </div>
            <div className='col-10 col-sm-7 col-md-5 col-lg-4 col-xl-3 mt-4 mx-md-3 mx-lg-5 mx-xl-3 mb-5'>
                <a className='mt-4 listedLink' href='https://ordinalsdirectory.com/engraved-dragons/' target="_blank">
                    <div className="card listedCard shadow d-flex align-items-center p-3">
                        <h2 className='mt-3 text-center'>Ordinals Directory</h2>
                        <img className='position-absolute listedImg mt-auto' src={require('../../assets/images/logos/ordinals.png')}/>
                        <p className='text-center mt-auto'>Find us on Ordinals Directory</p>
                    </div>
                </a>
            </div>
            <div className='col-10 col-sm-7 col-md-5 col-lg-4 col-xl-3 mt-4 mx-md-3 mx-lg-5 mx-xl-3 mb-5'>
                <a className='mt-4 listedLink' href='https://ordinalswallet.com/collection/engraved-dragons' target="_blank">
                    <div className="card listedCard shadow d-flex align-items-center p-3">
                        <h2 className='mt-3 text-center'>Ordinals Wallet</h2>
                        <img className='position-absolute listedImg mt-auto' src={require('../../assets/images/logos/ordinalswallet.png')}/>
                        <p className='text-center mt-auto'>The Engraved on Ordinals Wallet marketplace</p>
                    </div>
                </a>
            </div>
            <div className='col-10 col-sm-7 col-md-5 col-lg-4 col-xl-3 mt-4 mx-md-3 mx-lg-5 mx-xl-3 mb-5'>
                <a className='mt-4 listedLink' href='https://magiceden.io/ordinals/marketplace/engraved-dragons' target="_blank">
                    <div className="card listedCard shadow d-flex align-items-center p-3">
                        <h2 className='mt-3 text-center'>Magic Eden</h2>
                        <img className='position-absolute listedImg mt-auto' src={require('../../assets/images/logos/magiceden.png')}/>
                        <p className='text-center mt-auto'>Find the Ancients on Magic Eden</p>
                    </div>
                </a>
            </div>
            
            
        </div>
    )
}

export default Listed;