import Helmet from 'react-helmet';
import NavBar from "../../src/components/NavBar/NavBar"
import ImageEditor from '../../src/components/ImageEditor/ImageEditor'
import Footer from '../components/Footer/Footer';

function ImageEditorContainer(){
    return (
        <div className='overflowHidden'>
            <Helmet>
                <title>Engraved Dragons - Meme Maker</title>
                <meta property="og:title" content="Engraved Dragons - Meme Maker" />
                <meta name="twitter:title" content="Engraved Dragons - Meme Maker"></meta>
                <meta name="twitter:description" content="Use one of our meme templates to make your own Engraved Dragons memes by using our Meme Maker! Spread the Engraved Dragons Ordinals brand everywhere you go! "></meta>
                <meta property="og:description" content="Use one of our meme templates to make your own Engraved Dragons memes by using our Meme Maker! Spread the Engraved Dragons Ordinals brand everywhere you go! " />
                <meta name="description" content="Use one of our meme templates to make your own Engraved Dragons memes by using our Meme Maker! Spread the Engraved Dragons Ordinals brand everywhere you go! "/>
                <meta property="og:type" content="website" />
            </Helmet>
            <NavBar active='meme'/>
            <ImageEditor/>
            <Footer/>
        </div>
    )
}

export default ImageEditorContainer;