function Traits(props){
    return (
        <div>
            {props.menu.traits[props.item[0].toLowerCase()].map(trait => {
                return <li key={'mobile'+trait} style={{fontSize: '0.8rem'}} className="py-2"><a href="#" className="filterItem" onClick={e => props.toggleFilterOptions(e, trait[0])} data-item={props.item[0]}>{trait[0]}<span className="nrLabel px-2">{trait[1]}</span></a></li>
            })}
        </div>
        
    )
}

function subMobileItemsTraits(props){
    return (
                
                            <li className="d-flex flex-column col-6 col-md-3 justify-content-center py-2" key={'mobile'+props.item}>
                                <center>
                                    <div className="d-flex justify-content-center">
                                        <button style={{fontSize: '0.8rem'}} className="btn btn-toggle btn-toggleMobile align-items-center rounded collapsed col-10 justify-content-center subMenuItems" data-bs-toggle="collapse" data-bs-target={`#${props.item[0].toLowerCase()}-collapse`} aria-expanded="false">
                                                {props.item[0]}<span className="nrLabel px-2">{props.item[1]}</span>
                                        </button>
                                    </div>
                                    <div className="collapse" id={`${props.item[0].toLowerCase()}-collapse`}>
                                        <div className="d-flex flex-wrap justify-content-center col-10">
                                            <ul className="list-unstyled">
                                                <Traits menu={props.menu} item={props.item} toggleFilterOptions={props.toggleFilterOptions}/>
                                            </ul>
                                        </div>
                                    </div>
                                </center>
                            </li>
                
    )
}

export default subMobileItemsTraits;