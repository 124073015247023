import { useEffect } from 'react';
import {Link} from 'react-router-dom';

function NavBar(props){
    window.scrollTo(0, 0)
    useEffect(()=>{
        /*if(props.active === 'Home'){
            let homeNavLink = document.getElementById('home');
            homeNavLink.classList.add('active');
        }*/ if(props.active === 'Dragons'){
            let dragonsNavLink = document.getElementById('dragons');
            dragonsNavLink.classList.add('active');
        } else if(props.active === 'meme'){
            let memeNavLink = document.getElementById('meme');
            memeNavLink.classList.add('active');
        } else if(props.active === 'listed'){
            let listedNavLink = document.getElementById('listed');
            listedNavLink.classList.add('active');
        } else if(props.active === 'Eggs'){
            let listedNavLink = document.getElementById('eggs');
            listedNavLink.classList.add('active');
        }
    })
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light shadow">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/ancients"><img src={require('../../assets/images/logos/pictorial-black.png')} alt="" width="138" className="d-inline-block img-fluid"/></Link>
                
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse px-5 px-lg-0" id="navbarNav">
                    <ul className="navbar-nav">
                        {/*<li className="nav-item px-2">
                            <Link className="nav-link navColor" to="/" id='home'>HOME</Link>
    </li>*/}
                        <li className="nav-item px-2">
                            <Link className="nav-link navColor" to="/ancients" aria-current="page" id='dragons'>ANCIENTS</Link>
                        </li>
                        <li className="nav-item px-2">
                            <Link className="nav-link navColor" to="/eggs" aria-current="page" id='eggs'>EGGS</Link>
                        </li>
                        <li className="nav-item px-2">
                            <Link className="nav-link navColor" to="/memes" id='meme'>MEME MAKER</Link>
                        </li>
                        <li className="nav-item px-2">
                            <Link className="nav-link navColor"  to="/listings" id='listed'>LISTINGS</Link>
                        </li>
                    </ul>
                    <div className='navbar-brand d-lg-none d-flex justify-content-center'>
                    <a href='https://linktr.ee/engraveddragons' target="_blank" className='socialBtn hov'><i className="fa-solid fa-link me-4 linktree"/></a><a href='https://discord.gg/gdcszaPb9B' target="_blank" className='socialBtn hov'><i className="fab fa-discord me-4 discord"/></a><a href='https://twitter.com/engraveddragons' target="_blank" className='socialBtn hov'><i className="fab fa-twitter me-4 twitter"/></a>
                    </div>
                </div>
                <div className='navbar-brand d-none d-lg-flex' style={{float: 'right'}}>
                <a href='https://linktr.ee/engraveddragons' target="_blank" className='socialBtn hov'><i className="fa-solid fa-link me-4 linktree"/></a><a href='https://discord.gg/gdcszaPb9B' target="_blank" className='socialBtn hov'><i className="fab fa-discord me-4 discord"/></a><a href='https://twitter.com/engraveddragons' target="_blank" className='socialBtn hov'><i className="fab fa-twitter me-4 twitter"/></a>
                </div>
            </div>
        </nav>
    )
}

export default NavBar;