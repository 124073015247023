import '../css/bars.css';
import '../css/dragons.css';
import {Helmet} from "react-helmet";
import NavBar from '../components/NavBar/NavBar'
import MobileTraitsBar from '../components/TraitsBarMenu/MobileTraitsBar';
import DragonsContainer from '../components/Dragons/DragonsContainer';
import Footer from '../components/Footer/Footer';
import { toggleFilterOptions, handleSearchInput } from '../modules/search';

const menu = {
    items: ['Skins', 'Attributes', 'Background', 'Items'],
    subItems: {
        Skins: [['Green', 26], ['Blue', 26], ['Black', 17], ['Red', 16], ['Purple', 10], ['Gold', 3], ['Skeleton', 2]],
        Attributes: [['Clothes', 79], ['Head', 70], ['Glasses', 40], ['Smoking', 13], ['Earring', 4]],
        Background: [['Pink', 23], ['Yellow', 21], ['Purple', 19], ['Blue', 16], ['Red', 12], ['Green', 9]],
        'Items': [['0 Items', 5], ['1 Item', 25], ['2 Items', 32], ['3 Items', 35], ['4 Items', 3]]
    },
    traits: {
        head: [['Snapback Black Front', 6], ['Snapback Black Backwards', 4], ['Snapback Red Front', 6], ['Snapback Red Backwards', 5], ['Baseball Cap Front', 5],
        ['Cowboy', 3], ['Ushanka', 3], ['Bucket', 1], ['Big Cook', 7], ['Horns Beige', 3], ['Presley', 2], ['Tintin Golden', 2], ['Rose', 2], 
        ['Bandana Red', 2], ['Bandana Black', 4], ['Stray Hat', 2], ['Egg', 3], ['Caveman Hat', 1], ['Tophat Black', 2], ['Fire', 1], 
        ['Mage Hat', 1], ['Halo', 1], ['Horns Golden', 1], ['Crown', 1], ['Mayor Tophat', 2]], 
        clothes: [['White Shirt', 6], ['Black Jacket', 5], ['Black Hoodie', 5], ['Black Open Hoodie', 8], ['Lumberjack Yellow', 4], ['Red Open Hoodie', 8], ['Lumberjack Red', 3], 
        ['Caveman Shirt', 1], ['Red Hoodie', 7], ['Mage Robe', 1], ['Student', 7], ['Training Black', 7], ['Training Red', 6], ['Suit Black', 6], ['Mayor Suit', 5]],
        glasses: [['Pablo', 11], ['3D', 8], ['Red Glasses', 9], ['Bandit Mask', 2], ['Golden Glasses', 3], ['Slush', 7]],
        smoking: [['Cigarette', 4], ['Pipe', 2], ['Vape', 7]],
        earring: [['Silver Earring', 3], ['Golden Earring', 1]]
    }
}

function DragonShowcase() {

    /*function tooltip(){
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl, {trigger: 'hover'})
    }) 
    }*/

    return (
        <div className='overflowHidden'>
            <Helmet>
                <title>Engraved Dragons - The Engraved Showcase</title>
                <meta property="og:title" content="Engraved Dragons - The Engraved Showcase" />
                <meta name="twitter:title" content="Engraved Dragons - The Engraved Showcase"></meta>
                <meta name="twitter:description" content="First ever Engraved Dragons on the Bitcoin blockchain. Check out The Engraved in our showcase! Filter their traits and search on Ordinal inscription number."></meta>
                <meta property="og:description" content="First ever Engraved Dragons on the Bitcoin blockchain. Check out The Engraved in our showcase! Filter their traits and search on Ordinal inscription number." />
                <meta name="description" content="First ever Engraved Dragons on the Bitcoin blockchain. Check out The Engraved in our showcase! Filter their traits and search on Ordinal inscription number."/>
                <meta property="og:type" content="website" />
            </Helmet>
            <div className='page-container'>
                <NavBar active={'Dragons'}/>
                <MobileTraitsBar menu={menu} onInput={handleSearchInput} toggleFilterOptions={toggleFilterOptions}/>
                <div className='content-wrapper'>
                    <DragonsContainer menu={menu} toggleFilterOptions={toggleFilterOptions} searchHandler={handleSearchInput}/>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default DragonShowcase;


//First ever Engraved Dragons on the Bitcoin blockchain. Check out The Engraved in our showcase! Filter their traits and search on Ordinal inscription number.