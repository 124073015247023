import { downloadRef, memeRef, memeSizeRef } from './DragonsContainer'

function Modal(props) {
    return (
            <div className="modal fade" id="modal" tabIndex="-1" aria-labelledby="modalLabel" aria-hidden="true">{/*<!--MODAL-->*/}
                <div className="modal-dialog">
                    <div className="modal-content position-relative" ref={memeSizeRef}>
                        <div className="card position-absolute w-100 h-100 d-none" ref={memeRef} style={{zIndex: '9999'}}>
                            <div className="position-absolute top-0 start-100 translate-middle" style={{zIndex: '9999'}}>
                                <div className="rounded-circle card"><button className="btn-close" onClick={props.toggleMeme}></button></div>
                            </div>
                            <ul className="list-unstyled memesdiv scroll mt-3">
                                <li className="list-unstyled d-inline-flex align-items-center p-3 border-bottom col-12">
                                    <img loading='lazy' src={require(`../../assets/images/template/dragooon.png`)} className="col-3"/>
                                    <p className="ms-3 px-1">Get a custom speech or thought bubble here!</p>
                                    <div>
                                    <a className="btn btn-secondary " href="https://pixelspeechbubble.com/" target={"_blank"}>Go</a>
                                    </div>
                                </li>
                                <li className="list-unstyled d-inline-flex align-items-center p-3 border-bottom col-12">
                                    <img loading='lazy' src={require(`../../assets/images/sign/blacksign.png`)} className="col-3"/>
                                    <p className="ms-3 px-1 col-3">Get a sign for custom text.</p>
                                    <div>
                                    <div className='d-inline-flex align-items-center px-3 smallBtns'><div className='d-flex flex-wrap justify-content-center'><a className="btn btn-secondary badge mx-2 mt-2 mt-sm-0 downloadButtons" onClick={() => props.downloadImg(require(`../../assets/images/sign/redsign.png`), `redsign.png`)}>Red</a><a className="btn btn-secondary badge mx-2 mt-2 mt-sm-0 downloadButtons" onClick={() => props.downloadImg(require(`../../assets/images/sign/bluesign.png`), `bluesign.png`)}>Blue</a><a className="btn btn-secondary badge mx-2 mt-2 downloadButtons" onClick={() => props.downloadImg(require(`../../assets/images/sign/greensign.png`), `greensign.png`)}>Green</a><a className="btn btn-secondary badge mx-2 mt-2 downloadButtons" onClick={() => props.downloadImg(require(`../../assets/images/sign/blacksign.png`), `blacksign.png`)}>Black</a><a className="btn btn-secondary badge mx-2 mt-2 downloadButtons" onClick={() => props.downloadImg(require(`../../assets/images/sign/purplesign.png`), `purplesign.png`)}>Purple</a><a className="btn btn-secondary badge mx-2 mt-2 downloadButtons" onClick={() => props.downloadImg(require(`../../assets/images/sign/goldsign.png`), `goldsign.png`)}>Gold</a><a className="btn btn-secondary badge mx-2 mt-2 downloadButtons" onClick={() => props.downloadImg(require(`../../assets/images/sign/skeletonsign.png`), `skeletonsign.png`)}>Skeleton</a></div></div>
                                    </div>
                                </li>
                                <li className="list-unstyled d-inline-flex align-items-center p-3 border-bottom col-12">
                                    <img loading='lazy' src={require(`../../assets/images/template/rectex.png`)} className="col-3"/>
                                    <p className="ms-3 px-1">Start with an empty rectangle. Ideal for adding a custom speech or thought bubble to your dragon.</p>
                                    <div>
                                        <button className="btn btn-secondary" onClick={() => props.chosenTemplate(`template/rectangle.png`)} data-bs-dismiss="modal">Go</button>
                                    </div>
                                </li>
                                <li className="list-unstyled d-inline-flex align-items-center p-3 border-bottom col-12">
                                    <img loading='lazy' src={require(`../../assets/images/template/squareex.png`)} className="col-3"/>
                                    <p className="ms-3 px-1 col-4 col-sm-5">Start with an empty square. Add a custom background image or sign with your dragon in front.</p>
                                    <div>
                                        <div className='d-inline-flex align-items-center px-3 smallBtns'><div className='d-flex flex-wrap justify-content-center'><button className="btn btn-secondary badge downloadButtons" onClick={() => props.chosenTemplate(`template/square565.png`)} data-bs-dismiss="modal">565px</button><button className="btn btn-secondary badge mx-2 mt-2 downloadButtons" onClick={() => props.chosenTemplate(`template/square1000.png`)} data-bs-dismiss="modal">1000px</button><button className="btn btn-secondary badge mx-2 mt-2 downloadButtons" onClick={() => props.chosenTemplate(`template/square1500.png`)} data-bs-dismiss="modal">1500px</button><button className="btn btn-secondary badge mx-2 mt-2 downloadButtons" onClick={() => props.chosenTemplate(`template/square2000.png`)} data-bs-dismiss="modal">2000px</button><button className="btn btn-secondary badge mx-2 mt-2 downloadButtons" onClick={() => props.chosenTemplate(`template/square2500.png`)} data-bs-dismiss="modal">2500px</button></div></div>
                                    </div>
                                </li>
                                <li className="list-unstyled d-inline-flex align-items-center p-3 border-bottom col-12">
                                    <img loading='lazy' src={require(`../../assets/images/template/transparentex.png`)} className="col-3"/>
                                    <p className="ms-3 px-1">Your dragon with a transparent background. Add your customized text or images.</p>
                                    <div>
                                    <button className="btn btn-secondary" onClick={() => props.chosenTemplate(`transparent/${props.imageID}.png`)} data-bs-dismiss="modal">Go</button>
                                    </div>
                                </li>
                                <li className="list-unstyled d-inline-flex align-items-center p-3 col-12">
                                    <img loading='lazy' src={require(`../../assets/images/template/basicex.png`)} className="col-3"/>
                                    <p className="ms-3 px-1">Make a meme from your dragon Ordinal with its original background.</p>
                                    <div>
                                    <button className="btn btn-secondary" onClick={() => props.chosenTemplate(`${props.imageID}.png`)} data-bs-dismiss="modal">Go</button>
                                    </div>
                                </li>
                            </ul>
                        </div>


                        <div className="modal-header">
                            <h5 className="modal-title" id="modalLabel">{'Engraved Dragon #' + props.imageID}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body pb-0">
                            <figure>
                                <a target="_blank" href={`https://ordinals.com/inscription/${props.imageOID}`}><img src={require(`../../assets/images/${props.imageID}.png`)} className="figure-img img-fluid rounded" alt={'#' + props.imageID} id="image"/></a>
                                <div className="card downloadDiv bg-light shadow d-none" ref={downloadRef}>
                                    <div className="position-absolute top-0 start-100 translate-middle">
                                        <div className="rounded-circle card"><button className="btn-close" onClick={props.toggleDownload}></button></div>
                                    </div>
                                    <div className="d-flex flex-wrap justify-content-center align-items-center">
                                        <div><a className="btn btn-secondary mx-2 mt-2 downloadButtons" onClick={() => props.downloadImg(require(`../../assets/images/${props.imageID}.png`), `${props.imageID}.png`)}>Basic</a><a className="btn btn-secondary mx-2 mt-2 downloadButtons" onClick={() => props.downloadImg(require(`../../assets/images/transparent/${props.imageID}.png`), `transparent-${props.imageID}.png`)}>Transparent Background</a></div>
                                        <div className='d-inline-flex align-items-center px-3 smallBtns mt-2'><div className='col-3' style={{whiteSpace: 'nowrap'}}>Sign:</div><div className='col-9 d-flex flex-wrap justify-content-center'><a className="btn btn-secondary badge mx-2 mt-2 downloadButtons" onClick={() => props.downloadImg(require(`../../assets/images/sign/redsign.png`), `redsign.png`)}>Red</a><a className="btn btn-secondary badge mx-2 mt-2 downloadButtons" onClick={() => props.downloadImg(require(`../../assets/images/sign/bluesign.png`), `bluesign.png`)}>Blue</a><a className="btn btn-secondary badge mx-2 mt-2 downloadButtons" onClick={() => props.downloadImg(require(`../../assets/images/sign/greensign.png`), `greensign.png`)}>Green</a><a className="btn btn-secondary badge mx-2 mt-2 downloadButtons" onClick={() => props.downloadImg(require(`../../assets/images/sign/blacksign.png`), `blacksign.png`)}>Black</a><a className="btn btn-secondary badge mx-2 mt-2 downloadButtons" onClick={() => props.downloadImg(require(`../../assets/images/sign/purplesign.png`), `purplesign.png`)}>Purple</a><a className="btn btn-secondary badge mx-2 mt-2 downloadButtons" onClick={() => props.downloadImg(require(`../../assets/images/sign/goldsign.png`), `goldsign.png`)}>Gold</a><a className="btn btn-secondary badge mx-2 mt-2 downloadButtons" onClick={() => props.downloadImg(require(`../../assets/images/sign/skeletonsign.png`), `skeletonsign.png`)}>Skeleton</a></div></div>
                                    </div>
                                </div>
                                <center><figcaption className="figure-caption caption d-flex flex-wrap flex-md-nowrap justify-content-center" id="caption" style={{wordWrap: 'break-word'}}><a href={`https://ordinals.com/inscription/${props.imageOID}`} target="_blank" className='mt-3 px-1 px-md-3 me-2 btn btn-outline-secondary'>Ordinals Explorer</a><a className='px-1 px-md-3 mt-3 btn btn-outline-secondary d-flex align-items-center' onClick={props.toggleMeme}>Meme Templates</a><a className='px-1 px-md-3 ms-2 mt-3 btn btn-outline-secondary d-flex align-items-center' onClick={props.toggleDownload}>Download</a></figcaption></center>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Modal;