import Helmet from 'react-helmet';
import NavBar from '../components/NavBar/NavBar'
import Listed from '../components/Listed/Listed'
import Footer from '../components/Footer/Footer';

function ListedContainer(){
    return (
        <div className='overflowHidden' style={{minHeight: '100vh'}}>
            <Helmet>
                <title>Engraved Dragons - Listings</title>
                <meta property="og:title" content="Engraved Dragons - Listings" />
                <meta name="twitter:title" content="Engraved Dragons - Listings"></meta>
                <meta name="twitter:description" content="Check out the Engraved Dragons listings on everything Ordinals related. Find us on Ordswap, ORDX, Ordinals Directory and many more Bitcoin Ordinal focussed wesbites! "></meta>
                <meta property="og:description" content="Check out the Engraved Dragons listings on everything Ordinals related. Find us on Ordswap, ORDX, Ordinals Directory and many more Bitcoin Ordinal focussed wesbites! " />
                <meta name="description" content="Check out the Engraved Dragons listings on everything Ordinals related. Find us on Ordswap, ORDX, Ordinals Directory and many more Bitcoin Ordinal focussed wesbites! "/>
                <meta property="og:type" content="website" />
            </Helmet>
            <div className='page-container'>
                <NavBar active={'listed'}/>
                <div className='content-wrapper'>
                    <Listed/>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default ListedContainer;