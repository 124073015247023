import { BrowserRouter, Routes, Route } from "react-router-dom";

import ListedContainer from './pages/ListedContainer'
import DragonShowcase from './pages/DragonShowcase';
import EggShowcase from './pages/EggShowcase';
import Home from './pages/Home';
import ImageEditorContainer from './pages/ImageEditorContainer';
import NotFound from './NotFound';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ancients" element={<DragonShowcase />} />
        <Route path="/eggs" element={<EggShowcase />} />
        <Route path="/memes" element={<ImageEditorContainer />} />
        <Route path="/listings" element={<ListedContainer />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}


export default App;
