function Traits(props){
    return (
        <div>
        {props.menu.traits[props.item[0].toLowerCase()].map(trait => {
            return <li key={'desktop'+trait} style={{fontSize: '0.8rem'}} className="py-2 indent"><a className="filterItem" onClick={e => props.toggleFilterOptions(e, trait[0])} style={{color: 'rgb(33, 37, 41)'}} data-item={props.item[0]}>{trait[0]}<span className="nrLabel px-2">{trait[1]}</span></a></li>
        })}
    </div>
    )
}

function subDesktopItemsTraits(props){
    return (
                
                           <li className="mb-1" key={'desktop-'+props.item}>
                                
                                    <div className="d-flex">
                                        <button style={{fontSize: '0.8rem'}} className="btn btn-toggle btn-toggleMobile align-items-center rounded collapsed col-11 subMenuItems" data-bs-toggle="collapse" data-bs-target={`#${props.item[0].toLowerCase()}-collapse`} aria-expanded="false">
                                                {props.item[0]}<span className="nrLabel px-2">{props.item[1]}</span>
                                        </button>
                                    </div>
                                    <div className="collapse" id={`${props.item[0].toLowerCase()}-collapse`}>
                                        <div className="d-flex flex-wrap">
                                                <ul className="ps-5 list-unstyled">
                                                <Traits menu={props.menu} item={props.item} toggleFilterOptions={props.toggleFilterOptions}/>
                                                </ul>
                                        </div>
                                    </div>
                                
                            </li>
                
    )
}

export default subDesktopItemsTraits;