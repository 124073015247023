import SubDesktopTraitItems from "./SubDesktopTraitItems";

function SubDesktopItems(props) {
    const currentItem = props.currentItem;

    return(
        <div>
            {props.menu.subItems[currentItem].map(subItem => {
                if(subItem[0] === 'Clothes' || subItem[0] === 'Head' || subItem[0] === 'Glasses' || subItem[0] === 'Smoking' || subItem[0] === 'Earring'){
                    return <SubDesktopTraitItems item={subItem} menu={props.menu} toggleFilterOptions={props.toggleFilterOptions}/>
                } else {
                    return <li key={'desktop-'+subItem[0]+subItem[1]} style={{fontSize: '0.8rem'}} className="py-1 d-flex align-items-center"><a className={props.isItems ? 'itemCount' : 'filterItem'} onClick={props.isItems ? null : e => props.toggleFilterOptions(e, subItem[0])} style={{color: 'rgb(33, 37, 41)'}} data-item={currentItem}>{subItem[0]}<span className="nrLabel px-2">{subItem[1]}</span></a></li>
                }
            })}
        </div>
    )
}

export default SubDesktopItems;