import dragonData from '../../assets/data/_metadata.json';
import { useState } from 'react';

function Dragons(props) {
    const [ImageLoaded, setImageLoaded] = useState()

    const handleImageLoaded = () => {
        setImageLoaded('loaded')
    }

    return (
        <div className="col-xxl-10 col-lg-9 d-flex flex-wrap justify-content-evenly dragons" id="content" style={{height: '100vh'}}> {/* screenwidth >= 1500px ? col-xl-10 : col-lg-9*/}
                    { 
                        dragonData.map(dragon => {
                            return (
                                <div className="p-3 search" key={dragon.name}>
                                    <figure className="figure placeholder-glow">
                                        {!ImageLoaded &&  <img className='placeholder figure-img img-fluid rounded shadow' style={{backgroundColor: 'grey', width: '250px', height: '250px'}}/>}
                                        <img loading='lazy' onLoad={handleImageLoaded} src={require(`../../assets/images/${dragon.name}.png`)} className="figure-img img-fluid rounded shadow" alt={"#" + dragon.name} onClick={props.setID} data-bs-toggle="modal" data-bs-target="#modal"/>
                                        <figcaption className="figure-caption">#{dragon.name}</figcaption>
                                        <div className="d-flex flex-wrap traitBadges">
                                            {
                                                dragon.attributes.map(traits => {
                                                    if(traits.value !== 'empty'){
                                                        return <span className="badge bg-secondary m-1 shadow color" key={dragon.name + traits.value}>{traits.value}</span>
                                                    } else {
                                                        return;
                                                    }
                                                })
                                            }
                                        </div>
                                    </figure>
                                </div>
                            )
                        })
                    }
            </div>
    )
}

export default Dragons;